import { Controller } from "stimulus";

export default class extends Controller {
  initialize() {
    const options = {
      theme: "bootstrap4",
    };
    const ajaxUrl = this.element.dataset.resultsUrl;
    const allOption = this.element.dataset.allOption;
    const autosubmit = this.element.dataset.autosubmit;
    const form = $(this.element).closest("form");

    if (ajaxUrl !== undefined && ajaxUrl !== "") {
      options.ajax = {
        url: ajaxUrl,
        dataType: "json",
        data: function (params) {
          let urlParams = new URLSearchParams(window.location.search);
          let locale = urlParams.get('locale');
          locale ||= 'ja';
          let query = {
            q: params.term,
            locale: locale,
          }
          return query;
        },
        processResults: function (data, params) {
          let results = $.map(data.results, function (item) {
            return {
              id: item.id,
              text: item.name,
            };
          });
          if (
            (!params.hasOwnProperty("term") || params.term === "") &&
            allOption !== ""
          ) {
            results = [
              {
                id: "",
                text: allOption,
              },
            ].concat(results);
          }

          return {
            results: results,
          };
        },
      };
    }
    $(this.element).select2(options);
    $(this.element).on("select2:select", function () {
      if (autosubmit === "yes") {
        form.submit();
      }
    });
  }
}
