import { Controller } from 'stimulus';

// this stimulus style is workaround for this issue
// https://github.com/stimulusjs/stimulus/issues/221
// we have to wait to back style like as hello controller untill babel fix this issue or stimulus have workaround

class DeletePaidAtBaseController extends Controller {
  public paidAtTarget: HTMLInputElement;
}

export default class DeletePaidAtController extends (Controller as typeof DeletePaidAtBaseController) {
  public static targets = ['paidAt'];

  onClickDelete() {
    this.paidAtTarget.value = '';
  }
}
