import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["source"];
  static values = { list: Array };

  connect() {
    this.listValues = this.confirmAttribute();
    this.selectTargetLocale();
  }

  selectTargetLocale() {
    const targetLocaleCount = document.getElementsByClassName(
      `variable_locale_inputs_${this.sourceTarget.value}`
    ).length;
    this.setDefaultAttribute(targetLocaleCount);
    this.chosenTargetLocale(targetLocaleCount);
  }

  confirmAttribute() {
    const targetAttributes = document.getElementsByClassName(
      `variable_locale_inputs_${this.sourceTarget.value}`
    );
    const requiredList = [];
    for (let i = 0; i < targetAttributes.length; i++) {
      if (targetAttributes[i].getElementsByTagName("input")[0]) {
        requiredList.push(
          targetAttributes[i]
            .getElementsByTagName("input")[0]
            .hasAttribute("required")
        );
      } else if (targetAttributes[i].getElementsByTagName("textarea")[0]) {
        requiredList.push(
          targetAttributes[i]
            .getElementsByTagName("textarea")[0]
            .hasAttribute("required")
        );
      }
    }
    return requiredList;
  }

  chosenTargetLocale(targetLocaleCount) {
    for (let i = 0; i < targetLocaleCount; i++) {
      const variableLocaleInputs = document.getElementsByClassName(
        `variable_locale_inputs_${this.sourceTarget.value}`
      )[i];
      variableLocaleInputs.removeAttribute("hidden");

      if (this.listValues[i]) {
        this.setAttribute(variableLocaleInputs, "input", [
          "required",
          this.listValues[i],
        ]);
        this.setAttribute(variableLocaleInputs, "textarea", [
          "required",
          this.listValues[i],
        ]);
      } else {
        this.removeAttribute(variableLocaleInputs, "input", "required");
        this.removeAttribute(variableLocaleInputs, "textarea", "required");
      }
    }
    document.getElementById('contentLocale').value = this.sourceTarget.value;
  }

  setDefaultAttribute(targetLocaleCount) {
    const availableLocale = document.head
      .querySelector("meta[name=available_locale]")
      .content.slice(1)
      .slice(0, -1)
      .split(",");
    availableLocale.forEach((locale) => {
      const targetAttribute = locale.trim().replace(/["']/g, "");
      for (let i = 0; i < targetLocaleCount; i++) {
        const variableLocaleInputs = document.getElementsByClassName(
          `variable_locale_inputs_${targetAttribute}`
        )[i];
        variableLocaleInputs.setAttribute("hidden", "true");
        this.removeAttribute(variableLocaleInputs, "input", "required");
        this.removeAttribute(variableLocaleInputs, "textarea", "required");
      }
    });
  }

  setAttribute(element: Element, tag, keyvalue: [string, string]) {
    if (element.getElementsByTagName(tag).length) {
      element
        .getElementsByTagName(tag)[0]
        .setAttribute(keyvalue[0], keyvalue[1]);
    }
  }

  removeAttribute(element: Element, tag, attribute) {
    if (element.getElementsByTagName(tag)[0]) {
      element.getElementsByTagName(tag)[0].removeAttribute(attribute);
    }
  }
}
