import { Controller } from "stimulus";
import * as bootstrap from 'bootstrap';

export default class extends Controller {
  static targets = ["vccCardFront", "loadingText", "errorText", "vccEntityControllerPath"];

  get vccCardFront(): HTMLImageElement {
    return this.vccCardFrontTarget;
  }

  get loadingText(): HTMLElement {
    return this.loadingTextTarget;
  }

  get errorText(): HTMLElement {
    return this.errorTextTarget;
  }

  get vccEntityControllerPath(): string {
    return this.vccEntityControllerPathTarget.textContent;
  }

  async open(event) {
    const entityId = event.target.getAttribute('data-entity-id');

    const modal = new bootstrap.Modal(document.getElementById('vccInfoModal'));
    modal.show();

    this.loadingText.classList.remove('d-none');
    this.errorText.classList.add('d-none');
    this.vccCardFront.classList.add('d-none');

    try {
      const response = await fetch(`/admin/${this.vccEntityControllerPath}/${entityId}/virtual_credit_card`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.log('Error response:', response);
        console.log(errorData);
        this.errorText.textContent = errorData.errors.join(', ');
        this.errorText.classList.remove('d-none');
        return;
      }

      const data = await response.json();

      if (data.card_front) {
        this.vccCardFront.src = `data:image/png;base64,${data.card_front}`;
        this.vccCardFront.classList.remove('d-none');
      }
    } catch (error) {
      console.error('Unknown error while fetching VCC:', error);
      this.errorText.textContent = 'An error occurred';
      this.errorText.classList.remove('d-none');
    } finally {
      this.loadingText.classList.add('d-none');
    }
  }
}