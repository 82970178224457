import { Controller } from "stimulus";
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';

export default class CalendarController extends Controller {
  public static targets = ["downloadCsvForm", "calendar", "plan"];

  initialize() {
    const sources = this.planTargets.map(plan => {
      return {
        url: this.calendarTarget.dataset.url,
        extraParams: { property_plan_id: plan.dataset.id },
        color: getComputedStyle(plan).backgroundColor,
      }
    });

    this.calendar = new Calendar(this.calendarTarget, {
      plugins: [dayGridPlugin],
      locale: this.calendarTarget.dataset.locale,
      eventSources: sources,
      eventOrder: ['property_plan_id'],
    });
    this.calendar.render();
  }

  downloadCsv() {
    let url = new URL(this.downloadCsvFormTarget.dataset.url);

    if (event.target.dataset.term == 'month') {
      const year = this.calendar.getDate().getFullYear();
      const month = this.calendar.getDate().getMonth() + 1;
      url.searchParams.append('date', [year, month, 1].join('-'));
    }

    window.location.href = url;
  }
}
