import { Controller } from "stimulus";

export default class ReservationController extends Controller {
  public static targets = ["form"];

  submit(event) {
    this.formTarget.action = event.target.dataset.url;
    this.formTarget.submit();
  }
}
