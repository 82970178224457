// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";

export default class extends Controller {
  nameTarget: HTMLInputElement;
  outputTarget: HTMLElement;
  static targets = ["name", "output"];

  greet(): void {
    this.outputTarget.textContent = `Hello, ${this.nameTarget.value}!`;
  }
}
// sample html to work this stimulus sample
// <div data-controller="hello">
//   <input data-target="hello.name" type="text">

//   <button data-action="click->hello#greet">Greet</button>

//   <span data-target="hello.output"></span>
// </div>
