import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "toggleCheckbox",
    "jobIdCheckbox",
    "bulkRerunButton",
    "searchFormCollapseButton",
    "searchFormCollapseIcon",
  ];

  get toggleCheckbox(): HTMLInputElement {
    return this.toggleCheckboxTarget;
  }

  get jobIdCheckboxes(): HTMLInputElement[] {
    return this.jobIdCheckboxTargets;
  }

  get bulkRerunButtons(): HTMLButtonElement[] {
    return this.bulkRerunButtonTargets;
  }

  get searchFormCollapseButton(): HTMLAnchorElement {
    return this.searchFormCollapseButtonTarget;
  }

  get searchFormCollapseIcon(): SVGElement {
    return this.searchFormCollapseIconTarget;
  }

  connect() {
    super.connect();
    this.bulkRerunButtons.forEach((e) => (e.disabled = true));
    this.toggleCheckbox.disabled = this.jobIdCheckboxes.every(
      (e) => e.disabled
    );
  }

  toggleCollapseIcon() {
    setTimeout(() => {
      if (this.searchFormCollapseButton.ariaExpanded === "true") {
        this.searchFormCollapseIcon.classList.replace(
          "fa-chevron-right",
          "fa-chevron-down"
        );
      } else {
        this.searchFormCollapseIcon.classList.replace(
          "fa-chevron-down",
          "fa-chevron-right"
        );
      }
    });
  }

  toggleAll() {
    if (this.toggleCheckbox.checked) {
      this.jobIdCheckboxes
        .filter((e) => !e.disabled)
        .forEach((e) => (e.checked = true));
    } else {
      this.jobIdCheckboxes
        .filter((e) => !e.disabled)
        .forEach((e) => (e.checked = false));
    }
    this.updateBulkRerunButtonState();
  }

  onChangeJobCheckbox() {
    if (
      this.jobIdCheckboxes.filter((e) => !e.disabled).every((e) => e.checked)
    ) {
      this.toggleCheckbox.checked = true;
    } else if (
      this.jobIdCheckboxes.filter((e) => !e.disabled).some((e) => e.checked)
    ) {
      this.toggleCheckbox.indeterminate = true;
    } else {
      this.toggleCheckbox.checked = false;
    }
    this.updateBulkRerunButtonState();
  }

  private updateBulkRerunButtonState(): void {
    this.bulkRerunButtons.forEach(
      (e) =>
        (e.disabled =
          !this.toggleCheckbox.checked && !this.toggleCheckbox.indeterminate)
    );
  }
}
