import { Controller } from "stimulus"

import Rails from "@rails/ujs";

export default class extends Controller {
  initialize() {
    this.getUnreadMessage();
  }

  getUnreadMessage() {
    Rails.ajax({
      type: 'GET',
      url: '/admin/conversations/unread_message',
      success: function (data) {
        if (data.has_unread) {
          document.getElementById('unread-message-badge').style.display = 'inline';
        }
      }
    });
  }
}
