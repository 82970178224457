import { Controller } from "stimulus";

export default class NeighborController extends Controller {
  public static targets = ["form", "list", "id", "referralCode", "email", "nickname", "name"];

  render_list() {
    const params = new URLSearchParams({
      id: this.idTarget.value,
      referral_code: this.referralCodeTarget.value,
      email: this.emailTarget.value,
      nickname: this.nicknameTarget.value,
      name: this.nameTarget.value,
      select_name: this.formTarget.dataset.selectName,
    });
    const url = new URL(this.formTarget.dataset.url)
    url.search = params.toString();

    this.listTarget.innerHTML = 'Loading...';

    fetch(url)
      .then(response => response.text())
      .then(html => {
        this.listTarget.innerHTML = html;
    })
  }
}
