import { Controller } from 'stimulus';
import { Tooltip } from 'bootstrap';

export default class extends Controller {
  static targets = ['copiable'];
  static values = {
    content: String
  }

  connect() {
    this.copiableTargets.forEach(element => {
      const tooltip = new Tooltip(element, {
        title: 'Click to Copy',
        placement: 'top',
        trigger: 'manual'
      });

      element.addEventListener('mouseover', () => {
        tooltip.show();
      });

      element.addEventListener('mouseout', () => {
        tooltip.hide();
      });

      element.addEventListener('click', (event) => {
        if (event.currentTarget instanceof HTMLElement) {
          this.copy(event.currentTarget, tooltip);
        }
      });
    });
  }

  copy(element: HTMLElement, tooltip: Tooltip) {
    const content = element.getAttribute('data-copy-content-value') || this.contentValue;
    navigator.clipboard.writeText(content).then(() => {
      this.showCopiedTooltip(element, tooltip);
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  }

  showCopiedTooltip(element: HTMLElement, tooltip: Tooltip) {
    tooltip.hide();
    element.setAttribute('data-original-title', 'Copied!');
    tooltip.show();

    setTimeout(() => {
      tooltip.hide();
      setTimeout(() => {
        element.setAttribute('data-original-title', 'Click to Copy');
      }, 200);
    }, 1000);
  }

  disconnect() {
    this.copiableTargets.forEach(element => {
      const tooltip = Tooltip.getInstance(element);
      if (tooltip) {
        tooltip.dispose();
      }
    });
  }
}