// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("turbolinks").start();

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// https://github.com/rails/webpacker/blob/master/docs/assets.md
require.context("images", true);

import "javascripts/admin/stimulus";
import "javascripts/admin/fontawesome";

import "bootstrap";
import "styles/admin";
$(document).on("turbolinks:load", function () {
  $.rails.refreshCSRFTokens();
  $("[data-toggle='tooltip']").tooltip();
  $('[data-toggle="popover"]').popover();
  $('[checked="checked"]').parent().addClass("active");
});
