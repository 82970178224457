import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["translateButton", "originalButton"];
  cache = {};

  toggle(event) {
    event.preventDefault();

    const button = event.currentTarget;
    const messageID = button.getAttribute('data-translate-message-id');
    const messageElement = document.querySelector(`[data-message-id="${messageID}"]`);
    const translateButton = this.translateButtonTarget;
    const originalButton = this.originalButtonTarget;

    if (translateButton.style.display === 'none') {
      const originalContent = button.getAttribute('data-original-content');
      messageElement.innerText = originalContent;
      translateButton.style.display = 'inline';
      originalButton.style.display = 'none';
    } else {
      if (this.cache[messageID]) {
        const translatedContent = this.cache[messageID];
        messageElement.innerText = translatedContent;
        translateButton.style.display = 'none';
        originalButton.style.display = 'inline';
      } else {
        Rails.ajax({
          type: 'POST',
          url: `/admin/messages/${messageID}/translations`,
          success: (data) => {
            const translatedContent = data.translated_content;
            this.cache[messageID] = translatedContent;

            button.setAttribute('data-original-content', messageElement.innerText);
            messageElement.innerText = translatedContent;

            translateButton.style.display = 'none';
            originalButton.style.display = 'inline';
          }
        });
      }
    }
  }
}
