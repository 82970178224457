import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["reasonLabel", "reasonText", 'reviewModalForm', 'freeCancellationAction'];

    review(event) {
        // Reset the form action
        this.reviewModalFormTarget.action = '';

        // Set the reason text on the modal
        this.reasonTextTarget.innerHTML = event.currentTarget.dataset.reason.replace("\n", '<br />');

        // Prepare the form action based on the button clicked
        const reservationId = event.currentTarget.dataset.reservationId;
        const freeCancellationRequestId = event.currentTarget.dataset.id;
        this.reviewModalFormTarget.action = `/admin/reservations/${reservationId}/free_cancellation_requests/${freeCancellationRequestId}`;
    }

    approve() {
        this.freeCancellationActionTarget.value = 'approve';
        this.submit();
    }

    reject() {
        this.freeCancellationActionTarget.value = 'reject';
        this.submit();
    }

    submit() {
        this.reviewModalFormTarget.submit();
    }
}
