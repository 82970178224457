import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['textarea'];

  onClickItem(e) {
    const content = e.srcElement.getAttribute('data-message-template-content');
    this.textareaTarget.value = content;
  }
}
