import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['checkItems'];

  onCheckItem(e) {
    if (!e.srcElement) return

    document.querySelectorAll('.form-check-input.auto-reply').forEach((ele) => {
      if (e.srcElement.id !== ele.id) {
        ele.checked = false
      }
    })
  }
}
