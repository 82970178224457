import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["previousValue", "newValue", 'propertyField', 'OtaField', 'OtaId']

  show(event) {
    let button = event.currentTarget;

    let property_field_container = document.querySelector('.property_field_value.active');
    let property_field_value = property_field_container.querySelector('.property-field-existing-value');
    let property_current_value = property_field_value.innerHTML.trim();
    property_current_value = property_current_value.replace('w-50', '')

    let property_field = property_field_value.getAttribute('data-property-field');
    let ota_field = property_field_value.getAttribute('data-ota-field');

    let ota_id_value_container = property_field_container.querySelector('.ota-id-value.active');
    let ota_id = ota_id_value_container.getAttribute('data-ota-id');
    let ota_id_value = ota_id_value_container.innerHTML.trim();
    ota_id_value = ota_id_value.replace('w-50', '')

    this.previousValueTarget.innerHTML = property_current_value;
    this.newValueTarget.innerHTML = ota_id_value;
    this.propertyFieldTarget.value = property_field;
    this.OtaFieldTarget.value = ota_field;
    this.OtaIdTarget.value = ota_id;
  }
}
