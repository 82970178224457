import { Controller } from "stimulus";

export default class extends Controller {
  saveWithTranslation(event) {
    document.getElementById('forcefulTranslation').value = 'true';
  }

  saveWithoutTranslation(event) {
    document.getElementById('skipTranslation').value = 'true';
  }
}
