import { Controller } from "stimulus";

import Rails from "@rails/ujs";

export default class FooterTemplateController extends Controller {
  initialize() {

  }

  render(e) {
    const locale = e.srcElement.getAttribute('data-locale')
    const value = document.getElementById("footer_template_"+locale+"_content").value

    Rails.ajax({
      type: 'POST',
      url: '/admin/footer_templates/preview',
      dataType: "json",
      data: new URLSearchParams({'content': value}).toString(),
      success: function (data) {
        document.getElementById("footer_template_"+locale+"_content_preview").innerHTML = data.result;
      }
    });
  }
}
