import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["statusSelect", "ignoreCMGroup"];

  get statusSelect(): HTMLSelectElement {
    return this.statusSelectTarget;
  }

  get ignoreCMGroup(): HTMLDivElement {
    return this.ignoreCMGroupTarget;
  }

  connect() {
    super.connect();
  }

  onChangeStatusSelect(event: Event) {
    if (event.target.value === 'declined') {
        this.ignoreCMGroup.style.display = 'block';
    } else {
        this.ignoreCMGroup.style.display = 'none';
    }
  }

  //statusSelect
}
