import { Controller } from 'stimulus';

export default class CopyController extends Controller {
  public static targets = ['stock'];

  onClickCopy() {
    const firstRecordStock = this.stockTargets[0].value;
    this.stockTargets.forEach((element, index) => {
      const stock = Number(this.stockTargets[index].innerText);
      element.value = firstRecordStock;
    });
  }
}
