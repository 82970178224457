import { Controller } from 'stimulus';

export default class CheckSubmitController extends Controller {
  public static targets = ['wday', 'submit', 'quantityChecked', 'closedChecked', 'quantityFields', 'closedFields'];

  initialize() {
    this.toggleField();
  }

  switch() {
    const noChecked = this.wdayTargets.every(element => !element.checked);
    this.submitTarget.disabled = noChecked;
  }

  toggleField() {
    this.quantityFieldsTarget.hidden = !this.quantityCheckedTarget.checked;
    this.closedFieldsTarget.hidden = !this.closedCheckedTarget.checked;
  }
}
