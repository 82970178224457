import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["seasonSelector", "dateSelectors"];

  get seasonSelector(): HTMLSelectElement {
    return this.seasonSelectorTarget;
  }

  get dateSelectors(): HTMLDivElement {
    return this.dateSelectorsTarget;
  }

  onChangeSeasonSelector(event: Event) {
    if (event.target.value === 'flexible_date') {
        this.dateSelectors.classList.remove('d-none');
    } else {
        this.dateSelectors.classList.add('d-none');
    }
  }
}
