import { Controller } from "stimulus";
import firebase from "javascripts/admin/firebase";
import axios from "axios";

// this stimulus style is workaround for this issue
// https://github.com/stimulusjs/stimulus/issues/221
// we have to wait to back style like as hello controller untill babel fix this issue or stimulus have workaround

class SessionBaseController extends Controller {
  public emailTarget: HTMLInputElement;
  public passwordTarget: HTMLInputElement;
}

export default class SessionController extends (Controller as typeof SessionBaseController) {
  public static targets = ["email", "password"];
}
