import { Controller } from "stimulus";

import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['facilityTag'];

  initialize(){
    this.addActive();
  }

  getFacilityTag(event) {
    let propertyRoomTypeId = event.target.value;
    if (propertyRoomTypeId == '') {
      this.clearCheckboxSelection();
    } else {
      Rails.ajax({
        type: 'GET',
        url: `/admin/property_room_types/${propertyRoomTypeId}/facility_tags`,
        success: (data) => {
          if (data['ids'].length > 0) {
            this.clearCheckboxSelection();
            data['ids'].forEach(function (id) {
              document
                .querySelector(`label[for="property_room_type_attribute_tag_ids_${id}"]`)
                .classList.add('active');
              document.getElementById(`property_room_type_attribute_tag_ids_${id}`).checked = true
            });
          } else {
            this.clearCheckboxSelection();
          }
        },
      });
    }
  }

  clearCheckboxSelection() {
    this.facilityTagTargets.forEach((item) => {
      let checkBoxId = item.getAttribute('for');
      item.classList.remove('active');

      document.getElementById(checkBoxId).checked = false
    });
  }

  addActive(){
    this.facilityTagTargets.forEach((item) => {
      let checkBoxId = item.getAttribute('for');

      if (document.getElementById(checkBoxId).checked == true)
        item.classList.add('active');
    });
  }
}
