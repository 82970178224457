import { Controller } from 'stimulus';
import { Sortable } from 'sortablejs';
import noImage from '../../../images/admin/no_image.jpg'

export default class extends Controller {
  INVOICE_REGISTERED = "registered"

  static targets = [
    "invoiceRegistrationNumberStatusContainer",
    "invoiceRegistrationNumberContainer",
    "businessNameContainer",
    "countrySelector",
    "contractControls",
    "activeContractRadio",
    "stopSellContractRadio",
    "offboardingContractRadio",
    "suspendedAt",
  ]

  initialize() {
    Sortable.create(document.getElementById('property-image-fields'));
    this.showInvoiceFieldAppropriately()
    this.listenToCountryChangesForInvoiceFields()
  }

  handleUpload(e) {
    const { target: { files } } = e;
    const image = files[0];
    const previewUrl = URL.createObjectURL(image);

    const index = e.srcElement.getAttribute('data-index')
    const targetImage = document.getElementById(`property-image-${index}`)
    targetImage.src = previewUrl
  }

  addNewFileField() {
    const cardNodes = document.getElementsByClassName('property-image-card');
    const clone = cardNodes[0].cloneNode(true);
    const thumbEle = clone.querySelector('.card-img-top');
    const fileForm = clone.querySelector('.form-control-file');
    const captionEle = clone.querySelector('.img-caption');
    const idEle = clone.querySelector('.img-id');
    const checkbox = clone.querySelector('.img-destroy');
    const checkboxLabel = clone.querySelector('.form-check-label');

    thumbEle.src = noImage;
    thumbEle.id = `property-image-${cardNodes.length}`
    fileForm.value = '';
    fileForm.name = `property[property_images][${cardNodes.length}][image]`;
    fileForm.dataset.index = cardNodes.length
    captionEle.value = '';
    captionEle.name = `property[property_images][${cardNodes.length}][caption]`;
    idEle.value = '';
    idEle.name = `property[property_images][${cardNodes.length}][id]`;
    checkbox.checked = false;
    checkbox.name = `property[property_images][${cardNodes.length}][_destroy]`;
    checkbox.id = `property[property_images][${cardNodes.length}][_destroy]`;
    checkboxLabel.setAttribute('for', `property[property_images][${cardNodes.length}][_destroy]`);

    document.getElementById('property-image-fields').appendChild(clone);
  }

  showInvoiceFieldAppropriately() {
    const selectedCountry = this.selectedCountry

    // if not choosing Japan, hide the status changer and all other invoice-related fields
    const invoiceRegistrationNumberStatusContainerEl = this.invoiceRegistrationNumberStatusContainerTarget
    const isInJapan = selectedCountry === 'Japan' || selectedCountry === '日本'
    if (isInJapan) invoiceRegistrationNumberStatusContainerEl.classList.remove('d-none')
    else {
      invoiceRegistrationNumberStatusContainerEl.classList.add('d-none')
      this.displayInvoiceRelatedField(false)
      return
    }

    // show other status input fields only for property with govt-registered invoice data
    const inputs = invoiceRegistrationNumberStatusContainerEl.getElementsByTagName('INPUT')
    for (let i = 0; i < inputs.length; i++) {
      const input = inputs[i]
      if (input.type != 'radio') continue;
      if (input.checked && input.value === this.INVOICE_REGISTERED) {
        this.displayInvoiceRelatedField(true)
        return
      }
    }
    this.displayInvoiceRelatedField(false)
  }

  selectInvoiceRegistrationStatus(event) {
    this.displayInvoiceRelatedField(event.target.value === this.INVOICE_REGISTERED)
  }

  displayInvoiceRelatedField(shouldDisplay: Boolean) {
    const invoiceRegistrationNumberContainerEl = this.invoiceRegistrationNumberContainerTarget
    const businessNameContainerEl = this.businessNameContainerTarget

    // toggle the container's visibility
    if (shouldDisplay) {
      invoiceRegistrationNumberContainerEl.classList.remove("d-none")
      businessNameContainerEl.classList.remove("d-none")
    } else {
      invoiceRegistrationNumberContainerEl.classList.add("d-none")
      businessNameContainerEl.classList.add("d-none")
    }

    // toggle input fields editability
    invoiceRegistrationNumberContainerEl.querySelectorAll("input").forEach((i) => i.disabled = !shouldDisplay)
    businessNameContainerEl.querySelectorAll("input").forEach((i) => i.disabled = !shouldDisplay)
  }

  get selectedCountry(): string {
    const countrySelectorEl = this.countrySelectorTarget
    // console.log("a", $(countrySelectorEl).find(':selected')[0].text)
    for (let i = 0; i < countrySelectorEl.options.length; i++) {
      const selectedCountry = countrySelectorEl.options[i]
      if (selectedCountry.value == countrySelectorEl.value) {
        return selectedCountry.text
      }
    }
  }

  listenToCountryChangesForInvoiceFields() {
    const countrySelectorEl = this.countrySelectorTarget
    $(countrySelectorEl).on("select2:select", (e, x) => {
      this.showInvoiceFieldAppropriately()
    })
  }

  selectContractStatus(event) {
    const activeContractRadioEl = this.activeContractRadioTarget
    const stopSellContractRadioEl = this.stopSellContractRadioTarget
    const offboardingContractRadioEl = this.offboardingContractRadioTarget

    if(event.target.value === 'true') {
      // Property: Public
      // Contract: Active or Stop Sale
      activeContractRadioEl.checked = true

      activeContractRadioEl.disabled = false
      stopSellContractRadioEl.disabled = false
      offboardingContractRadioEl.disabled = true
    } else {
      // Property: Non-Public
      // Contract: Off boarding
      // Suspension date could be set
      activeContractRadioEl.disabled = true
      stopSellContractRadioEl.disabled = true
      offboardingContractRadioEl.disabled = false

      offboardingContractRadioEl.checked = true
    }

    this.mangerSuspendedAt()
  }

  mangerSuspendedAt() {
    const stopSellContractRadioEl = this.stopSellContractRadioTarget
    const offboardingContractRadioEl = this.offboardingContractRadioTarget
    const activeContractRadioEl = this.activeContractRadioTarget

    // Suspension date could be set for only Stop Sale / Offboarding contract
    if (stopSellContractRadioEl.checked) {
      this.suspendedAtTarget.disabled = false
      this.suspendedAtTarget.required = true
    } else if(offboardingContractRadioEl.checked) {
      this.suspendedAtTarget.disabled = false
      this.suspendedAtTarget.required = false
    } else {
      this.suspendedAtTarget.required = false
      this.suspendedAtTarget.disabled = true
    }

    if (activeContractRadioEl.checked) {
      this.suspendedAtTarget.value = ''
    }
  }
}
