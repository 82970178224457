import {Controller} from "stimulus";

export default class extends Controller {
    static targets = [
        "operationTypeSelect",
        "coinRow",
    ];

    get operationTypeSelect(): HTMLSelectElement {
        return this.operationTypeSelectTarget;
    }

    get coinRow(): HTMLElement {
        return this.coinRowTarget;
    }

    connect() {
        super.connect();
    }

    changeSelect() {
        const updateCoinOperations = ["cs_update_other_coin", "cs_update_purchased_coin"];
        if (updateCoinOperations.includes(this.operationTypeSelect.value)) {
            this.coinRow.style.display = 'table-row';
        } else {
            this.coinRow.style.display = 'none';
        }
    }
}
